import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AkkhorLayout from "../layout/akkhor";
import Axios from "axios";
import { useFormik } from "formik";
import Spinner from "../component/Spinner";
import { URL_LIST_ALL } from "../configs/api";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import SearchBoxModal from "../component/SearchBoxModal";
import AlertErrorForm from "../component/AlertErrorForm";
import * as Yup from "yup";

export default function XuatBangChamCong(props) {
    const { user } = useSelector((state) => state.auth);
    const [month, setMonth] = useState([]);
    const [year, setYear] = useState([]);
    const [linkDown, setLinkDown] = useState([]);
    const [listCompany, setListCompany] = useState([]);
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: { month: new Date().getMonth() + 1, year: new Date().getFullYear() },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            month: Yup.string().trim().nullable().required('Vui lòng chọn tháng'),
            year: Yup.string().trim().nullable().required('Vui lòng chọn năm')
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            let _url = `${URL_LIST_ALL}users_onmonth?export=true`;
            if (values.part_id) _url += `&part_id=${values.part_id}`;
            if (values.company_id) _url += `&company_id=${values.company_id}`;
            if (values.month && values.year) _url += `&time=${values.year}-${values.month}`;

            setLinkDown([]);
            setLoading(true);

            if (!values.company_id && listCompany.length > 0) {
                const fetchDataSequentially = async () => {
                    for (const item of listCompany) {
                        try {
                            const response = await Axios.get(`${_url}&company_id=${item.id}`, user.config);
                            const data_insert = { title: item.title, data: response.data.data };
                            setLinkDown(prev => [...prev, data_insert]); // Cập nhật từng phần tử vào mảng
                        } catch (error) {
                            console.error(`Lỗi khi lấy dữ liệu công ty ID ${item.id}:`, error);
                        }
                    }
                    setLoading(false);
                };
                await fetchDataSequentially();
            } else {
                Axios.get(_url, user.config)
                    .then(res => {
                        setLoading(false);
                        if (res.data.status === "success") {
                            resetForm();
                            const data_insert = { title: 'File', data: res.data.data };
                            setLinkDown(prev => [...prev, data_insert]); // Cập nhật từng phần tử vào mảng
                        } else {
                            confirmAlert({ title: 'Lỗi', message: res.data.message, buttons: [{ label: 'OK' }] });
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        confirmAlert({ title: 'Lỗi', message: err.message, buttons: [{ label: 'OK' }] });
                        console.log(err);
                    });
            }
        },
    });

    useEffect(() => {
        setMonth(Array.from({ length: 12 }, (_, i) => i + 1));
        setYear(Array.from({ length: new Date().getFullYear() - 2020 }, (_, i) => 2021 + i));
    }, []);

    useEffect(() => {
        Axios.get(`${URL_LIST_ALL}company?limit=1000`, user.config)
            .then(res => {
                if (res.data.status === "success") {
                    setListCompany(res.data.data.map(item => ({ id: item.id, title: item.title })));
                } else {
                    confirmAlert({ title: 'Lỗi', message: res.data.message, buttons: [{ label: 'OK' }] });
                }
            })
            .catch(err => {
                confirmAlert({ title: 'Lỗi', message: err.message, buttons: [{ label: 'OK' }] });
                console.log(err);
            });
    }, []);

    return (
        <AkkhorLayout idrole="list-users">
            <div className="breadcrumbs-area">
                <ul>
                    <li><Link to="/">Trang chủ</Link></li>
                    <li><Link to="/list-users_onday">Danh sách chấm công</Link></li>
                    <li>Xuất bảng chấm công</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Xuất bảng Chấm công</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12 mg-t-8">
                                <div className="col-12 col-sm-4 form-group">
                                    <label>Chọn tháng</label>
                                    <select className="form-select height32" {...formik.getFieldProps("month")}>
                                        {month.map((item, i) => (
                                            <option key={i} value={item < 10 ? '0' + item : item}>Tháng {item}</option>
                                        ))}
                                    </select>
                                    <AlertErrorForm formik={formik} name="month" />
                                </div>
                                <div className="col-12 col-sm-4 form-group">
                                    <label>Chọn năm</label>
                                    <select className="form-select height32" {...formik.getFieldProps("year")}>
                                        {year.map((item, i) => (
                                            <option key={i} value={item}>{item}</option>
                                        ))}
                                    </select>
                                    <AlertErrorForm formik={formik} name="year" />
                                </div>
                                <div className="col-12 col-sm-4 form-group">
                                    <label>Xuất theo Công ty</label>
                                    <SearchBoxModal
                                        apiname="company"
                                        placeholder="Chọn công ty"
                                        all={true}
                                        colvalue="id"
                                        colname="title"
                                        formik={formik}
                                        name="company_id"
                                        onChange={async (e) => await formik.setFieldValue('company_id', e)}
                                    />
                                    <AlertErrorForm formik={formik} name="company_id" />
                                </div>

                                <div className="alert alert-primary" role="alert">
                                    <button type="submit" disabled={formik.isSubmitting && (listCompany.length > 0)} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark mr-2">
                                        {formik.isSubmitting || loading ? <Spinner /> : <>Xuất File</>}
                                    </button>
                                </div>
                            </div>

                            {linkDown.length > 0 && (
                                <div className="row">
                                    {linkDown.map((item, i) => (
                                        <div className="col text-center mb-2" key={i}>
                                            <a className="btn btn-danger" href={item.data}>Tải về: {item.title}</a>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
