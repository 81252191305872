
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";

export default function DanhSachDatXe() {


  let columns = [
    { name: "id", label: "ID"},
    { name: "title", label: "Tên xe" },
    { name: "car_id", label: "Biển số xe" },
    { name: "author", label: "Người tạo" },
  ];

  return (<AkkhorLayout idrole="dashboard">
    <div className="breadcrumbs-area">
        <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
            <li>
              Quản lý xe
            </li>
        </ul>
    </div>
   
    <SRList name="car_booking" linkaction="car-booking" idrole="quan-ly-xe" params={''} title="" defaultLimit={50} serverSide={true} columns={columns} >
    </SRList>
  </AkkhorLayout>);
}
