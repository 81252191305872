import React, { useState, useEffect } from "react";
import Select from "react-select";
import Axios from "axios";
import { useSelector } from "react-redux";
import { URL_LIST_ALL } from "../configs/api";
const CarSelect = ({ onChange }) => {
    const [listCar, setListCar] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCar, setSelectedCar] = useState(null);
    const { user } = useSelector((state) => state.auth);
    // Gọi API để lấy danh sách xe
    useEffect(() => {
        Axios.get(URL_LIST_ALL + "cars", user.config)
            .then((res) => {
                if (res.data.status === "success") {
                    setListCar(res.data.data);
                } else {
                    console.log(res.data.message);
                }
            })
            .catch((err) => console.log("Lỗi API:", err))
            .finally(() => setLoading(false));
    }, []);

    // Chuyển dữ liệu API thành định dạng của react-select
    const formattedOptions = listCar.map((item) => ({
        value: item.id,
        label: item.title,
        car_id: item.car_id,
        color: item.color,
    }));

    // Custom hiển thị giá trị đã chọn
    const customSingleValue = ({ data }) => (
        <div style={{ gap: "10px" }}>
            <span
                style={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: data.color,
                    display: "inline-block",
                    borderRadius: "50%",
                    marginRight: "10px",
                }}
            ></span>
            <span>{data.car_id}</span>
            <span style={{ fontSize: "16px", color: "#888", marginLeft: "5px" }}>
                ({data.label})
            </span>
        </div>
    );

    // Custom hiển thị option trong dropdown
    const customSingleOption = (props) => {
        const { data, innerRef, innerProps } = props;
        return (
            <div
                ref={innerRef}
                {...innerProps}
                style={{
                  
                    padding: "8px",
                    cursor: "pointer",
                    backgroundColor: props.isFocused ? "#f0f0f0" : "white",
                }}
            >
                <span
                    style={{
                        width: "16px",
                        height: "16px",
                        backgroundColor: data.color,
                        display: "inline-block",
                        borderRadius: "50%",
                        marginRight: "10px",
                    }}
                ></span>
                <span style={{ flexGrow: 1 }}>{data.car_id}</span>
                <span style={{ fontSize: "16px", color: "#888", marginLeft:5 }}>
                    ({data.label})
                </span>
            </div>
        );
    };

    return (
        <div className="w-100">
            {loading ? (
                <div className="form-control">Đang tải dữ liệu...</div>
            ) : (
                <Select
                    options={formattedOptions}
                    getOptionLabel={(e) => (
                        <div >
                            <span
                                style={{
                                    width: "16px",
                                    height: "16px",
                                    backgroundColor: e.color,
                                    display: "inline-block",
                                    borderRadius: "50%",
                                    marginRight: "10px",
                                }}
                            ></span>
                            <span>{e.car_id}</span>
                            <span
                                style={{
                                    fontSize: "16px",
                                    color: "#888",
                                    marginLeft: "5px",
                                }}
                            >
                                ({e.label})
                            </span>
                        </div>
                    )}
                    value={selectedCar}
                    onChange={(selected) => {
                        setSelectedCar(selected);
                        onChange && onChange(selected); // Gọi callback khi chọn
                    }}
                    components={{
                        SingleValue: customSingleValue,
                        Option: customSingleOption,
                    }}
                    isSearchable={false}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            minHeight: "40px", // ✅ Giữ chiều cao cố định
                            height: "40px", 
                        }),
                        valueContainer: (provided) => ({
                            ...provided,
                            display: "flex",
                            alignItems: "center",
                            minHeight: "40px", // ✅ Giữ nội dung không làm thay đổi kích thước
                        }),
                    }}
                />
            )}
        </div>
    );
};

export default CarSelect;
