
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";

export default function DanhSachXe() {


    let columns = [
        { name: "id", label: "ID" },
        {
            name: "car_id", label: "Thông tin xe",
            options: {
                filter: false, sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div className="w-100" style={{ minWidth: 200 }}>
                        <small>Tên xe:</small> <strong>{tableMeta.title}</strong><br />
                        <small>Biển số:</small> <strong>{tableMeta.car_id}</strong><br />
                    </div>
                },
            }
        },
        { name: "drive_name", label: "Người lái" },
        { name: "aprover_name", label: "Quản lý" },
        { name: "author", label: "Người tạo" },
    ];

    return (<AkkhorLayout idrole="dashboard">
        <div className="breadcrumbs-area">
            <ul>
                <li>
                    <Link to="/">Trang chủ</Link>
                </li>
                <li>
                    Quản lý xe
                </li>
            </ul>
        </div>

        <SRList name="cars" idrole="quan-ly-xe" params={''} title="" defaultLimit={50} serverSide={true} columns={columns} >
        </SRList>
    </AkkhorLayout>);
}
