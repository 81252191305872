import React, { useState, useEffect } from "react";
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from 'react-router-dom';
import Avatar from "../assets/img/figure/admin.jpg";
import { URL_LIST_ALL } from '../configs/api';
import { resetRedux } from "../redux/Actions";
import { actions } from "../redux/authRedux";
import Axios from "axios";
const _ = require('lodash');
//904832009
export default function SiderBar(props) {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const info = user && user.data ? user.data : null;
    const MyRole = JSON.parse(user && user.data.roles ? user.data.roles : '{}')
    const location = useLocation();
    const [data_dkgt, setData_dkgt] = useState(null)
    const [uAvatar, setUAvatar] = useState(null)
    let localsegment = location.pathname;

    let listMenus = [
        { title: 'Bảng điều khiển', icon: 'flaticon-dashboard', link: '/dashboard' },
        { title: 'Báo cáo công việc', icon: 'fas fa-chart-line', link: '/baocaocongviec' },
        {
            title: 'Dịch vụ hành chính', icon: 'fas fa-paper-plane', link: '', active: ['/van-thu', '/vanthu_param','/phan-cong-cap-so','/dat-xe','/dat-phong-hop','/quan-ly-xe','/quan-ly-phong-hop'].includes(localsegment), children: [
                { title: 'Văn thư lưu trữ', icon: 'fas fa-angle-right', link: '/van-thu' },
                { title: 'Phân loại văn thư', icon: 'fas fa-angle-right', link: '/vanthu_param' },
                { title: 'Phân công cấp số', icon: 'fas fa-angle-right', link: '/phan-cong-cap-so' },
                { title: 'Đặt xe', icon: 'fas fa-angle-right', link: '/dat-xe' },
                { title: 'Đặt phòng họp', icon: 'fas fa-angle-right', link: '/dat-phong-hop' },
                { title: 'Quản lý xe', icon: 'fas fa-angle-right', link: '/quan-ly-xe' },
                { title: 'Quản lý phòng họp', icon: 'fas fa-angle-right', link: '/quan-ly-phong-hop' },
            ]
        },
        {
            title: 'Dịch vụ công nghệ', icon: 'fas fa-biohazard', link: '', active: ['/quan-ly-qrcode'].includes(localsegment), children: [
                { title: 'Tạo QRcode', icon: 'fas fa-angle-right', link: '/quan-ly-qrcode' },
            ]
        },
        {
            title: 'Xin phép, Giải trình', icon: 'fas fa-business-time', link: '', active: ['/list-users-break', '/list-giaitrinh'].includes(localsegment), children: [
                { title: 'Xin nghỉ/Đi muộn/Về sớm', icon: 'fas fa-angle-right', link: '/list-users-break' },
                { title: 'Đăng ký đi làm ngày lễ/Cuối tuần', icon: 'fas fa-angle-right', link: '/dang-ky-lam-le' },
                { title: 'Giải trình chấm công', icon: 'fas fa-angle-right', link: '/list-giaitrinh' },
            ]
        },
        {
            title: 'Phê duyệt', icon: 'fas fa-address-card', link: '', active: ['/list-approve-break', 'list-duyet-giai-trinh'].includes(localsegment), children: [
                { title: 'Phê duyệt xin nghỉ/Đi muộn/Về sớm', icon: 'fas fa-angle-right', link: '/list-approve-break' },
                { title: 'Phê duyệt Đăng ký đi làm ngày lễ/Cuối tuần', icon: 'fas fa-angle-right', link: '/duyet-dang-ky-lam-le' },
                { title: 'Phê duyệt giải trình chấm công', icon: 'fas fa-angle-right', link: '/list-duyet-giai-trinh' },
            ]
        },
        {
            title: 'Chấm công', icon: 'fas fa-calendar-alt', link: '', active: ['/list-users_onday', '/list-cham-cong-thang', '/upload-chamcong-bpmax', '/bangchotcongthang'].includes(localsegment), children: [
                { title: 'Bảng chấm công chi tiết', icon: 'fas fa-angle-right', link: '/list-users_onday' },
                { title: 'Bảng chấm công tháng', icon: 'fas fa-angle-right', link: '/list-cham-cong-thang' },
                { title: 'Bảng chốt công tháng', icon: 'fas fa-angle-right', link: '/bangchotcongthang' },
                { title: 'Upload chấm công BPMAX', icon: 'fas fa-angle-right', link: '/upload-chamcong-bpmax' },
            ]
        },

        {
            title: 'Nhân sự', icon: 'flaticon-user', link: '', active: ['/list-users','/users_gitiho', '/list-thuc-tap-sinh', '/users_login'].includes(localsegment), children: [
                { title: 'Danh sách nhân sự', icon: 'fas fa-angle-right', link: '/list-users' },
                { title: 'Danh sách thực tập sinh', icon: 'fas fa-angle-right', link: '/list-thuc-tap-sinh' },
                { title: 'Danh sách ứng viên', icon: 'fas fa-angle-right', link: '/users_new' },
                { title: 'Quản lý tài khoản Gitiho', icon: 'fas fa-angle-right', link: '/users_gitiho' },
                { title: 'Theo dõi đăng nhập', icon: 'fas fa-angle-right', link: '/users_login' },
                { title: 'Mẫu hợp đồng', icon: 'fas fa-angle-right', link: '/mau-hop-dong' },
                { title: 'Hợp đồng', icon: 'fas fa-angle-right', link: '/hop-dong' },
            ]
        },

        {
            title: 'Quản lý trình nội bộ', icon: 'fas fa-atlas', link: '', active: ['/danh-muc-tin', '/danh-sach-tin'].includes(localsegment), children: [
                { title: 'Danh mục', icon: 'fas fa-angle-right', link: '/danh-muc-tin' },
                { title: 'Quy trình nội bộ', icon: 'fas fa-angle-right', link: '/danh-sach-tin' },
            ]
        },
        {
            title: 'Cài đặt tổ chức', icon: 'fas fa-archway', link: '', active: ['/list-parts','/lambu','/vanthu_param', '/list-chucvu', '/list-company', '/list-users_onday_settings', 'cai-dat-nhan-su'].includes(localsegment), children: [
                { title: 'Công ty thành viên', icon: 'fas fa-angle-right', link: '/list-company' },
                { title: 'Phòng ban', icon: 'fas fa-angle-right', link: '/list-parts' },
                { title: 'Danh sách chức vụ', icon: 'fas fa-angle-right', link: '/list-chucvu' },
                // { title: 'Ca làm việc', icon: 'fas fa-angle-right', link: '/list-ca-lam' },
                { title: 'Quản lý ca làm', icon: 'fas fa-angle-right', link: '/quan-ly-ca' },
                { title: 'Phân ca làm', icon: 'fas fa-angle-right', link: '/phan-ca-lam' },
                { title: 'Cài đặt nhân sự', icon: 'fas fa-angle-right', link: '/cai-dat-nhan-su' },
                { title: 'Ngày lễ, ngày nghỉ, NVP', icon: 'fas fa-angle-right', link: '/ngay-le' },
                { title: 'Quản lý ngày làm bù', icon: 'fas fa-angle-right', link: '/lambu' },
            ]
        },
        {
            title: 'Cài đặt hệ thống', icon: 'fas fa-cogs', link: '', active: ['/list-settings', '/test-email', '/danh-sach-gui-mail'].includes(localsegment), children: [
                { title: 'Cài đặt thông số', icon: 'fas fa-angle-right', link: '/list-settings' },
                { title: 'Phân quyền', icon: 'fas fa-angle-right', link: '/list-roles' },
                { title: 'Test gửi mail', icon: 'fas fa-angle-right', link: '/test-email' },
                { title: 'Danh sách gửi mail', icon: 'fas fa-angle-right', link: '/danh-sach-gui-mail' },
            ]
        },
    ];

    const checkRole = (idparent, menu) => {
        let isrole = false;
        let id = menu.id;
        if (!id) id = _.trimStart(menu.link, ' /');
        if (id) {
            if (idparent && MyRole.hasOwnProperty(idparent) && MyRole[idparent].children.hasOwnProperty(id) && MyRole[idparent].children[id].checked === true) isrole = true
            else if (MyRole.hasOwnProperty(id) && MyRole[id].checked === true) isrole = true
        } else {
            if (menu.hasOwnProperty('children') && menu.children && menu.children.length > 0) {
                menu.children.map((sub) => {
                    let idsub = _.trimStart(sub.link, ' /');
                    if (idparent && MyRole.hasOwnProperty(idparent) && MyRole[idparent].children.hasOwnProperty(idsub) && MyRole[idparent].children[idsub].checked === true) isrole = true
                    else if (idsub && MyRole.hasOwnProperty(idsub) && MyRole[idsub].checked === true) isrole = true
                    return sub;
                })
            }
        }
        // if(menu.link === '/marktests') console.log(MyRole);
        return isrole
    }
    //Hàm xử lý đăng xuất
    const dologout = () => {
        confirmAlert({
            title: "Đăng xuất",
            message: "Bạn có chắc muốn đăng xuất",
            closeOnEscape: true,
            closeOnClickOutside: true,
            buttons: [
                {
                    label: "Đăng xuất",
                    onClick: () => {
                        dispatch(resetRedux());
                        dispatch(actions.login(null));
                    },
                },
                { label: "Không" },
            ],
        });
    };

    const subMenu = (idparent, list, active) => {
        return <>
            {list && list.length > 0 ? <ul className={`nav sub-group-menu ` + (active ? 'menu-open' : '')} style={{ display: (active ? 'block' : 'none') }}>
                {list.map((item, i) => {
                    let title = item.title
                    let DKchoDuyet = data_dkgt && data_dkgt.DKchoDuyet ? data_dkgt.DKchoDuyet : 0
                    let GTchuaGui = data_dkgt && data_dkgt.GTchuaGui ? data_dkgt.GTchuaGui : 0
                    let DKchuaDuyet = data_dkgt && data_dkgt.DKchuaDuyet ? data_dkgt.DKchuaDuyet : 0
                    let GTchuaDuyet = data_dkgt && data_dkgt.GTchuaDuyet ? data_dkgt.GTchuaDuyet : 0
                    if (title === 'Xin nghỉ/Đi muộn/Về sớm' && DKchoDuyet > 0) {
                        title = <><span>Xin nghỉ/Đi muộn/Về sớm</span><span className="sub-sp">{DKchoDuyet}</span></>
                    } else if (title === 'Giải trình chấm công' && GTchuaGui > 0) {
                        title = <><span>Giải trình chấm công</span><span className="sub-sp">{GTchuaGui}</span></>
                    } else if (title === 'Phê duyệt xin nghỉ/Đi muộn/Về sớm' && DKchuaDuyet > 0) {
                        title = <><span>Phê duyệt xin nghỉ/Đi muộn/Về sớm</span><span className="sub-sp">{DKchuaDuyet}</span></>
                    } else if (title === 'Phê duyệt giải trình chấm công' && GTchuaDuyet > 0) {
                        title = <><span>Phê duyệt giải trình chấm công</span><span className="sub-sp">{GTchuaDuyet}</span></>
                    } else {
                        title = <span>{title}</span>
                    }
                    if (checkRole(idparent, item)) {
                        return <li className="nav-item" key={i}>
                            <Link to={item.link} className={`nav-link ${item.link === localsegment ? 'menu-active' : ''}`}>
                                <i className="fas fa-angle-right"></i>
                                {title}
                            </Link>
                        </li>
                    } else return null
                })}
            </ul> : null}
        </>
    }
    useEffect(function () {
        if (user) {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + user.access_token,
                    'Content-Type': 'application/json; charset=UTF-8'
                }
            }
            Axios.get(URL_LIST_ALL + "count_giaitrinh_xinnghi", config).then((res) => {
                if (res.data.status === "success") {
                    setData_dkgt(res.data.data)
                } else alert(res.data.message)
            }).catch((err) => console.log(err));
        }
    }, [user]);


    return (
        <>
            {/* Sidebar Area Start Here */}
            <div className="sidebar-main sidebar-menu-one sidebar-expand-md sidebar-color">
                <div className="mobile-sidebar-header d-md-none">
                    <div className="w-100 d-flex  u-info">
                        <div className="admin-img">
                            {uAvatar ?
                                <img src={uAvatar} alt="Admin" />
                            :
                                <img src={info && info.avatar ? info.avatar : Avatar} alt="Admin" />
                            }
                           
                        </div>

                        <div className="admin-title">
                            <h5 className="item-title">
                                {info.fullname} {" "}
                            </h5>
                            <span>{info.chucvu}</span>
                        </div>

                    </div>
                </div>
                <div className="sidebar-menu-content">
                    <ul className="nav nav-sidebar-menu sidebar-toggle-view">
                        {listMenus.map((item, i) => {
                            if (checkRole(null, item) || item.role) {
                                let title = item.title
                                let DKchoDuyet = data_dkgt && data_dkgt.DKchoDuyet ? data_dkgt.DKchoDuyet : 0
                                let GTchuaGui = data_dkgt && data_dkgt.GTchuaGui ? data_dkgt.GTchuaGui : 0
                                let DKchuaDuyet = data_dkgt && data_dkgt.DKchuaDuyet ? data_dkgt.DKchuaDuyet : 0
                                let GTchuaDuyet = data_dkgt && data_dkgt.GTchuaDuyet ? data_dkgt.GTchuaDuyet : 0

                                if (title === 'Xin phép, Giải trình' && (DKchoDuyet + GTchuaGui) > 0) {
                                    title = <><span>Xin phép</span><span className="sub-sp">{DKchoDuyet}</span><span> - Giải trình</span><span className="sub-sp">{GTchuaGui}</span></>
                                } else if (title === 'Phê duyệt' && (DKchuaDuyet + GTchuaDuyet) >0) {
                                    title = <><span>Phê duyệt</span><span className="sub-sp">{DKchuaDuyet + GTchuaDuyet}</span></>
                                } else {
                                    title = <span>{title}</span>
                                }
                                let menuactive = false
                                if (item.children) {
                                    item.children.map((itemMN, i) => {
                                        if (itemMN.link === localsegment) menuactive = true
                                    })
                                }
                                return <li key={i} className={`nav-item ${item.children ? 'sidebar-nav-item' : ''} ` + (menuactive ? 'active' : '')}>
                                    {!item.children ?
                                        <Link to={item.link} className={`nav-link ${item.link === localsegment ? 'menu-active' : ''}`}>
                                            <i className={item.icon}></i>{title}</Link>
                                        :
                                        <div to={item.link} className={`nav-link ${item.link === localsegment || menuactive ? 'menu-active' : ''}`}><i
                                            className={item.icon}></i>{title}</div>}
                                    {subMenu(item.id, item.children, menuactive)}
                                </li>
                            } else return null
                        })}
                        <li className="nav-item">
                            <a href="#logout" onClick={dologout} className="nav-link">
                                <i className="flaticon-turn-off"></i><span>Đăng xuất</span>
                            </a>
                        </li>
                    </ul>

                </div>
            </div>
            {/* Sidebar Area End Here */}
        </>
    );
}
