
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState, useRef } from "react";
import AkkhorLayout from "../layout/akkhor";
import QRCode from 'qrcode.react';
import AlertErrorForm from "../component/AlertErrorForm";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { confirmAlert } from "react-confirm-alert";


export default function FormQrcode() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [qrData, setQRData] = useState('');
    const qrRef = useRef(null);
    const [detail, setDetail] = useState(null);

    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "qrcode/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            if (mounted) {
                                setDetail(res.data.data);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id]
    );

    const formik = useFormik({
        initialValues: detail ? detail : { title: '', qr_content: '', user_id: user.data.id, qr_result: '' },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Nhập tiêu đề'),
            qr_content: Yup.string().trim().nullable().required('Nhập nội dung'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            var qr_content = values.qr_content

            setQRData(qr_content)

            console.log(values);
            setTimeout(() => {
                const canvas = qrRef.current.querySelector("canvas");
                const qrBase64 = canvas.toDataURL("image/png");
                values.qr_result = qrBase64;
                if (id > 0) {
                    Axios.put(URL_LIST_ALL + 'qrcode/' + id, values, user.config).then(res => {
                        setSubmitting(false);
                        if (res.data.status === "success") {

                            window.location.href = '/edit-quan-ly-qrcode/' + res.data.id
                        } else {
                            confirmAlert({
                                title: 'Lỗi',
                                message: res.data.message,
                                buttons: [{ label: 'OK' }]
                            });
                        }
                    }).catch(err => console.log(err));
                } else {
                    // console.log(values);
                    Axios.post(URL_LIST_ALL + 'qrcode', values, user.config).then(res => {
                        setSubmitting(false);
                        if (res.data.status === "success") {

                            window.location.href = '/edit-quan-ly-qrcode/' + res.data.id

                        } else {
                            confirmAlert({
                                title: 'Lỗi',
                                message: res.data.message,
                                buttons: [{ label: 'OK' }]
                            });
                        }
                    }).catch(err => console.log(err));
                }
            }, 200);
            setSubmitting(false)
        },
    });

    const downloadQRCode = () => {
        const url = formik.values['qr_result']; // Chuyển thành ảnh
        const a = document.createElement("a");
        a.href = url;
        a.download = "qrcode.png"; // Tên file tải xuống
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };


    return (
        <AkkhorLayout idrole="quan-ly-qrcode">
            <div className="card height-auto mt-5">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Tạo mã QRcode</h3>
                        </div>
                    </div>
                    <form className="new-added-form  form-info" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Tiêu đề <span className="text-danger">*</span></label>
                                <input type="text" placeholder="Nhập tiêu đề..." className="form-control height32" {...formik.getFieldProps("title")} />
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>
                            <div className="col-12"></div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Nội dung tạo <span className="text-danger">*</span></label>
                                <textarea placeholder="Nội dung..." className="form-control" name="qr_content" {...formik.getFieldProps("qr_content")} ></textarea>
                                <AlertErrorForm formik={formik} name="qr_content"></AlertErrorForm>
                            </div>
                            {qrData && (
                                <>
                                    <div ref={qrRef} className="mt-4 d-none">
                                        <QRCode value={qrData} size={300} />
                                    </div>
                                </>
                            )}
                            <div className="col-12"></div>
                            {formik.values['qr_result'] ?
                                <div className="col-xl-4 col-lg-6 col-12">
                                    <img className="mw-100" src={formik.values['qr_result']} />
                                    <div className="w-100 mt-2">
                                        <button type="button" className="btn btn-sm btn-primary" onClick={downloadQRCode}>
                                            Tải ảnh xuống
                                        </button>
                                    </div>
                                </div>
                                : ''}
                            <div className="col-12 form-group mt-5">
                                <button type="submit" className="btn btn-sm btn-success" >Tạo mã</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
