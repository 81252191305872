
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import SearchBoxModal from "../component/SearchBoxModal";
import CarSelect from "../component/CarSelect";

export default function FormDatXe() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [dateSelect, setDateSelect] = useState({ datestart: null, dateend: null })
    const [listCar, setListCar] = useState([])

    const dateNow = new Date();
    const dateset = new Date().getDay() <= 2 ? 4 : 3
    const backDate = new Date(dateNow.setDate(dateNow.getDate() - dateset))
    const [maxDatePicker, setMaxDatePicker] = useState(new Date(dateNow.setMonth(dateNow.getMonth() + 8)))

    const defaultdetail = {
        car_id: '', author_id: user.data.id, title: '', status: 1, color: "#00A5E3", datestart: '', dateend: '', drive_id: '', aprover_id: ''
    }
    useEffect(
        function () {
            Axios.get(URL_LIST_ALL + "cars", user.config).then(async (res) => {
                if (res.data.status === "success") {
                    setListCar(res.data.data);
                } else console.log(res.data.message);
            }).catch((err) => console.log(err));

            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "car_booking/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            if (mounted) {
                                let detail = res.data.data
                                setDetail(detail);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, refresh]
    );
    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Nhập tiêu đề').max(150, "Độ dài không quá 150 ký tự"),
            car_id: Yup.string().trim().nullable().required('Vui lòng chọn xe'),
            timestart: Yup.string().trim().nullable().required('Vui lòng chọn giờ bắt đầu'),
            timeend: Yup.string().trim().nullable().required('Vui lòng chọn giờ kết thúc'),
            datestart: Yup.string().trim().nullable().required('Vui lòng chọn ngày bắt đầu'),
            dateend: Yup.string().trim().nullable().required('Vui lòng chọn ngày kết thúc'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'car_booking/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật thông tin xe thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                // console.log(values);
                Axios.post(URL_LIST_ALL + 'car_booking', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới xe thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    const handleDate = (name, value) => {
        formik.setFieldValue(name, moment(value).format('YYYY-MM-DD HH:mm'))
        formik.setFieldTouched(name)
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)
        if (name === 'datestart') coreMaxdate(newDate.datestart)
    };

    const coreMaxdate = (date_start) => {
        if (date_start) {
            let month = date_start.getMonth() + 1
            let year = date_start.getFullYear()
            let day = date_start.getDate()
            if (day > 25) {
                setMaxDatePicker(new Date(year + '/' + (month + 1) + '/' + 25))
            } else {
                let dayNow = Math.round(new Date().getTime() / 100000000)
                if ((Math.round(date_start.getTime() / 100000000) + 1) < dayNow) {
                    let dateset = new Date(year + '/' + month + '/' + day + ' 23:59:59')

                    setMaxDatePicker(dateset)
                } else {
                    setMaxDatePicker(new Date(year + '/' + month + '/' + 25))
                }
            }
        }

    }

    return (
        <AkkhorLayout idrole="quan-ly-xe">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/quan-ly-dat-xe">Quản lý đặt xe</Link>
                    </li>
                    <li>{id ? 'Sửa thông tin đặt xe' : 'Đặt xe'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>{id ? 'Sửa thông tin đặt xe' : 'Đặt xe'}</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12 form-group">
                                <label>Tiêu đề <span className="text-danger">*</span></label>
                                <input type="text" placeholder="VD: Expander 30A12345" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>
                            <div className="col-12 form-group">
                                <label>Chọn xe</label>
                                <CarSelect onChange={(e) => formik.setFieldValue('car_id', e.value)} />
                            </div>
                            <div className="col-12 form-group">
                                <div className="row">
                                    <div className="col-sm-2 col-12 form-group">
                                        <label>Giờ bắt đầu: <span className="text-danger">*</span></label>
                                        <input type="time" placeholder="Thời gian" className="form-control height32" {...formik.getFieldProps("timestart")} />
                                    </div>
                                    <div className="col-sm-4 col-12 form-group">
                                        <label>Ngày bắt đầu: <span className="text-danger">*</span></label>
                                        <DateTimePicker onChange={(value) => { handleDate('datestart', value) }} value={dateSelect.datestart ? new Date(dateSelect.datestart) : null} format="dd-MM-y" className="form-control height32 air-datepicker" disableClock={true}
                                            hourPlaceholder="giờ" minutePlaceholder="phút" secondPlaceholder="giây" yearPlaceholder="Năm" monthPlaceholder="Tháng" dayPlaceholder="Ngày" minDate={dateSelect.datestart ? new Date(dateSelect.datestart) : backDate}></DateTimePicker>
                                        <AlertErrorForm formik={formik} name="datestart"></AlertErrorForm>
                                    </div>
                                    <div className="col-sm-2 col-12 form-group">
                                        <label>Giờ kết thúc: <span className="text-danger">*</span></label>
                                        <input type="time" placeholder="Thời gian" className="form-control height32" {...formik.getFieldProps("timeend")} />
                                    </div>
                                    <div className="col-sm-4 col-12 form-group">
                                        <label>Ngày kết thúc: <span className="text-danger">*</span></label>
                                        <DateTimePicker onChange={(value) => { handleDate('dateend', value) }} value={dateSelect.dateend ? new Date(dateSelect.dateend) : ''} format="dd-MM-y" className="form-control height32 air-datepicker" disableClock={true}
                                            hourPlaceholder="giờ" minutePlaceholder="phút" secondPlaceholder="giây" yearPlaceholder="Năm" monthPlaceholder="Tháng" dayPlaceholder="Ngày" minDate={dateSelect.datestart ? new Date(dateSelect.datestart) : new Date()} maxDate={maxDatePicker}></DateTimePicker>
                                        <AlertErrorForm formik={formik} name="dateend"></AlertErrorForm>
                                    </div>
                                </div>
                            </div>

                            
                            <div className="col-xs-12"></div>
                            <div className="col-12 col-sm-6 form-group">
                                <label>Người quản lý: (Người duyệt xe)</label>
                                <SearchBoxModal apiname={"users"} placeholder="Chọn người quản lý" colvalue={'id'} colname={'fullname'} defaultValue={formik.values['aprover_id']} name='aprover_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('aprover_id', e)
                                    }}></SearchBoxModal>
                            </div>
                            <div className="col-12 col-sm-6 form-group">
                                <label>Chọn tài xế</label>
                                <SearchBoxModal apiname={"users"} placeholder="Chọn tài xế" colvalue={'id'} colname={'fullname'} defaultValue={formik.values['drive_id']} name='drive_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('drive_id', e)
                                    }}></SearchBoxModal>
                            </div>

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
