
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import { URL_LIST_ALL } from "../configs/api";
import Axios from "axios";

export default function DanhSachVanThu() {
  const { user } = useSelector((state) => state.auth);
  const [params, setParam] = useState();
  const [filterShow, setFilterShow] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [type, setType] = useState(null)
  const [status, setStatus] = useState(null)
  const [show, setShow] = useState(false)
  const [title, setTitle] = useState('')
  const [vanThuParam, setVanThuParam] = useState([])
  const [listValue, setListValue] = useState([])
  const [baoCaoVanThu, setBaoCaoVanThu] = useState(null)


  const handleSubmit = (e) => {
    e.preventDefault();
    let _param = ''
    if (title && title !== '') _param += "&title=" + title
    if (type && type !== '') _param += "&type=" + type
    if (status && status !== '') _param += "&status=" + status
    if (startDate > 0 && endDate > 0) _param += "&daterange=" + startDate.format('YYYY-MM-DD') + "_" + endDate.format('YYYY-MM-DD')
    setParam(_param);
  };

  const handleCallback = (start, end, label) => {
    setStartDate(start)
    setEndDate(end)
  }

  useEffect(function () {
    if (user) {
      Axios.get(URL_LIST_ALL + "vanthu_param?orderby=title-ASC&limit=1000&choice=1", user.config).then(async (res) => {
        if (res.data.status == "success") {
          setVanThuParam(res.data.data)
        } else console.log(res.data.message);
      }).catch((err) => console.log(err));
      Axios.get(URL_LIST_ALL + "baocaovanthu", user.config).then(async (res) => {
        if (res.data.status == "success") {
          setBaoCaoVanThu(res.data)
        } else console.log(res.data.message);
      }).catch((err) => console.log(err));
    }
  }, [user]);// eslint-disable-line

  let columns = [
    { name: "id", label: "ID", options: { filter: false, sort: true } },
    {
      name: "fullname", label: "Người tạo",
      options: {
        filter: false, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div style={{minWidth:200}}>
            Họ tên: <strong>{value}</strong><br />
            MSNV: <strong>{tableMeta.user_code}</strong><br />
            Phòng ban: <strong>{tableMeta.part_code}</strong><br />
            Công ty: <strong>{tableMeta.company_code}</strong><br />
          </div>
        },
      }
    },
    { name: "title", label: "Tiêu đề" },
    { name: "type_name", label: "Loại văn thư" },
    {
      name: "code", label: "Mã văn thư",
      options: {
        filter: false, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{value ? value : 'Chưa cấp số'}</div>
        },
      }
    },
    {
      name: "approve_name", label: "Người cấp số",
      options: {
        filter: false, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div style={{minWidth:200}}>
            Họ tên: <strong>{value}</strong><br />
            MSNV: <strong>{tableMeta.approve_code}</strong><br />
            Phòng ban: <strong>{tableMeta.approve_part}</strong><br />
            Công ty: <strong>{tableMeta.approve_company}</strong><br />
          </div>
        },
      }
    },
    {
      name: "status", label: "Trạng thái",
      options: {
        filter: false, sort: true,
        customBodyRender: (value, tableMeta) => {
          let result = <div className="">
            {value == 6 && <span className="text-danger"><i className="far fa-pause-circle"></i>PHC đã từ chối cấp số</span>}
            {value == 5 && <span className="text-danger"><i className="fas fa-exclamation-circle"></i>Quản lý từ chối duyệt</span>}
            {value == 4 && <span className="text-success"><i className="fab fa-centos"></i> Hoàn tất việc cấp số</span>}
            {value == 3 && <span className="text-success"><i className="fab fa-centos"></i> Đã gửi file chờ xác nhận</span>}
            {value == 2 && <span className="text-success"><i className="fab fa-centos"></i> Đã cấp số chờ gửi file Scan</span>}
            {value == 1 && <span className="text-success"><i className="fab fa-centos"></i> Chờ cấp số</span>}
            {value == 0 && <span className="text-warning"><i className="fab fa-stumbleupon-circle"></i> Đã gửi chờ phê duyệt</span>}
            <br/>
            {tableMeta.approve_id == user.data.id && (value == 1 || value == 3) ? <strong className="text-danger">Vui lòng vào cấp số</strong> : ''}
            {tableMeta.user_id == user.data.id && (value == 2) ? <strong className="text-danger">Vui lòng gửi file scan</strong> : ''}
          </div>
          return result
        },
      }
    },
    { name: "datecreate", label: "Thời gian tạo" },
    { name: "ngayky", label: "Ngày ký" },
    {
      name: "datecheck", label: "Thời gian duyệt",
      options: {
        filter: false, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            let time = new Date(value * 1000)
            var result = <Moment format="hh:mm DD/MM/YYYY">{time}</Moment>
          } else {
            var result = ''
          }
          return result
        },
      }
    },
  ];

  const topbar = () => {

    return <div className="col-12 col-sm-9">
      <div className="row">
        <div className="col">
          <strong>Tổng số văn thư: </strong><span>{baoCaoVanThu ? baoCaoVanThu.tong : 0}</span>
        </div>
        <div className="col">
          <strong>Đã hoàn tất: </strong><span>{baoCaoVanThu ? baoCaoVanThu.dahoanthanh : 0}</span>
        </div>
        <div className="col">
          <strong>Chưa hoàn tất: </strong><span>{baoCaoVanThu ? baoCaoVanThu.chuahoanthanh : 0}</span>
        </div>
        <div className="col">
          <strong>Bị từ chối: </strong><span>{baoCaoVanThu ? baoCaoVanThu.bituchoi : 0}</span>
        </div>
      </div>
    </div>
  }

  return (<AkkhorLayout idrole="dashboard">
    <div className="breadcrumbs-area">
      <ul>
        <li>
          <Link to="/">Trang chủ</Link>
        </li>
        <li>
          Quản lý văn thư lưu trữ
        </li>
      </ul>
    </div>
    <div className="card card-filter mb-3">
      <div className="card-header">
        <div className="d-flex" onClick={() => setFilterShow(!filterShow)}>
          <div className="mr-auto">
            <h3 className="title">Tìm kiếm</h3>
          </div>
          <button className="btn btn-sm btn-defaul"><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
        </div>
      </div>
      <div className={filterShow ? "card-body" : 'hidden-filter'}>
        <form className="new-added-form" method="get" >
          <div className="row">
          <div className="col-md-6 col-sm-6 col-12 form-group">
              <label>Tiêu đề</label>
              <input type="text" placeholder="Tiêu đề" className="form-control height32" autoComplete="off" onChange={(e)=>setTitle(e.target.value)}/>
            </div>
            <div className="col-md-6 col-12 form-group">
              <label>Khoảng thời gian tạo</label>
              <DateRangePicker initialSettings={{ startDate: startDate, endDate: endDate, maxDate: new Date(), }}
                onCallback={handleCallback}
              >
                <div className='d-flex align-items-center'>
                  <input
                    className="form-control h-31 form-control-sm"
                    placeholder='Từ ngày'
                    readOnly={true}
                    onChange={(e) => setStartDate(e)}
                    value={startDate > 0 ? startDate.format('YYYY-MM-DD') : ""}
                  />
                  <span className='fas fa-arrow-right mx-4' />
                  <input
                    className="form-control h-31 form-control-sm"
                    placeholder='Đến ngày'
                    readOnly={true}
                    onChange={(e) => setEndDate(e)}
                    value={endDate > 0 ? endDate.format('YYYY-MM-DD') : ""}
                  />
                </div>
              </DateRangePicker>
            </div>
           
            
            <div className="col-sm-3 col-12 form-group">
              <label>Loại văn thư</label>
              <select className="form-select height32" onChange={(e) => setType(e.target.value)}>
                <option value="">Chọn loại văn thư</option>
                {vanThuParam && vanThuParam.map((item, i) => {
                  return <option value={item.id} key={i}>{item.title}</option>
                })}
              </select>
            </div>

            <div className="col-lg-3 col-12 form-group">
              <label>Trạng thái</label>
              <select className="form-select height32" onChange={(e) => setStatus(e.target.value)}>
                <option value="">Chọn trạng thái</option>
                <option value="1">Chờ cấp số</option>
                <option value="2">Đã cấp số, vui lòng gửi file scan</option>
                <option value="3">Đã gửi file scan chờ phê duyệt</option>
                <option value="4">Hoàn tất việc cấp số</option>
                <option value="6">PHC từ chối cấp số</option>
              </select>
            </div>

            <div className="col-12 ">
              <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <SRList name="vanthu" linkaction="van-thu" idrole="van-thu" params={params} title="Văn thư lưu trữ" defaultLimit={50} serverSide={true} columns={columns} TopBar={topbar()} listvalue={(e)=>setListValue(e)}>
    </SRList>

    <Modal show={show} onHide={() => setShow(false)} animation={true} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Quản lý file</Modal.Title>
      </Modal.Header>
      <Modal.Body className="body-image-box">
      </Modal.Body>
    </Modal>
  </AkkhorLayout>);
}
