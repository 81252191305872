import { useEffect, useState } from "react";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.min.js"; // Import worker
import HTMLFlipBook from "react-pageflip"; // Import Flipbook

// Cấu hình worker cho pdf.js
pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

function PdfBook() {
  const [pages, setPages] = useState([]);
  const pdfUrl = "https://apihr.bhsgroup.vn/download-image?url=uploads/2024/12/092022qd-pns-quy-dinh-che-do-phuc-loi.pdf"

  useEffect(() => {
    const loadPdf = async () => {
      try {
        const response = await fetch(pdfUrl);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const loadingTask = pdfjsLib.getDocument(url);
        const pdf = await loadingTask.promise;
        const numPages = pdf.numPages;

        let loadedPages = [];
        for (let i = 1; i <= numPages; i++) {
          const page = await pdf.getPage(i);
          const scale = 1.5;
          const viewport = page.getViewport({ scale });

          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          await page.render(renderContext).promise;
          loadedPages.push(canvas.toDataURL());
        }

        setPages(loadedPages);
      } catch (err) {
        console.error("Lỗi khi tải PDF:", err);
      }
    };

    if (pdfUrl) loadPdf();
  }, [pdfUrl]);

  return (
    <div className="pdf-book">
      {pages.length > 0 ? (
        <HTMLFlipBook
          width={window.innerWidth}
          height={window.innerHeight}

        >
          {pages.map((page, index) => (
            <div key={index} className="page">
              <img src={page} alt={`Page ${index + 1}`} />
            </div>
          ))}
        </HTMLFlipBook>
      ) : (
        <p>Đang tải PDF...</p>
      )}
    </div>
  );
}

export default PdfBook;
