
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import SearchBoxModal from "../component/SearchBoxModal";

export default function FormCars() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [dateSelect, setDateSelect] = useState({ han_dang_kiem: null })

    const defaultdetail = {
        car_id: '', author_id: user.data.id, title: '', status: 1, color: "#00A5E3", han_dang_kiem: '',drive_id:'',aprover_id:''
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "cars/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            if (mounted) {
                                let detail = res.data.data
                                setDetail(detail);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, refresh]
    );
    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Nhập tên xe').max(150, "Độ dài không quá 150 ký tự"),
            car_id: Yup.string().trim().nullable().required('Nhập biển số').max(150, "Độ dài không quá 150 ký tự")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'cars/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật thông tin xe thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                // console.log(values);
                Axios.post(URL_LIST_ALL + 'cars', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới xe thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    const handleDate = (name, value) => {
        formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'))
        formik.setFieldTouched(name)
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)
    };

    return (
        <AkkhorLayout idrole="quan-ly-xe">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/quan-ly-xe">Quản lý xe</Link>
                    </li>
                    <li>{id ? 'Sửa thông tin xe' : 'Thêm mới xe'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>{id ? 'Sửa thông tin xe' : 'Thêm mới xe'}</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12 col-sm-6 form-group">
                                <label>Tên xe <span className="text-danger">*</span></label>
                                <input type="text" placeholder="VD: Expander 30A12345" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-6 form-group">
                                <label>Biển số <span className="text-danger">*</span></label>
                                <input type="text" placeholder="VD: 30A12345" className="form-control height32" {...formik.getFieldProps("car_id")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>

                            <div className="col-12 list-color mb-2">
                                <label>Màu hiển thị</label>
                                <div className="row">
                                    <div className="col-6 col-sm-2 item-color form-check">
                                        <input type="radio" className="form-check-input" value="#00A5E3" checked={formik.values['color'] == "#00A5E3"} onChange={(e) => formik.setFieldValue('color', e.target.value)} />
                                        <span className="square form-check-label" style={{ backgroundColor: "#00A5E3" }}></span>
                                    </div>
                                    <div className="col-6 col-sm-2 item-color form-check">
                                        <input type="radio" className="form-check-input" value="#FC6238" checked={formik.values['color'] == "#FC6238"} onChange={(e) => formik.setFieldValue('color', e.target.value)} />
                                        <span className="square" style={{ backgroundColor: "#FC6238" }}></span>
                                    </div>
                                    <div className="col-6 col-sm-2 item-color form-check">
                                        <input type="radio" className="form-check-input" value="#C05780" checked={formik.values['color'] == "#C05780"} onChange={(e) => formik.setFieldValue('color', e.target.value)} />
                                        <span className="square" style={{ backgroundColor: "#C05780" }}></span>
                                    </div>
                                    <div className="col-6 col-sm-2 item-color form-check">
                                        <input type="radio" className="form-check-input" value="#00CDAC" checked={formik.values['color'] == "#00CDAC"} onChange={(e) => formik.setFieldValue('color', e.target.value)} />
                                        <span className="square" style={{ backgroundColor: "#00CDAC" }}></span>
                                    </div>
                                    <div className="col-6 col-sm-2 item-color form-check">
                                        <input type="radio" className="form-check-input" value="#FF5C77" checked={formik.values['color'] == "#FF5C77"} onChange={(e) => formik.setFieldValue('color', e.target.value)} />
                                        <span className="square" style={{ backgroundColor: "#FF5C77" }}></span>
                                    </div>
                                    <div className="col-6 col-sm-2 item-color form-check">
                                        <input type="radio" className="form-check-input" value="#FFEC59" checked={formik.values['color'] == "#FFEC59"} onChange={(e) => formik.setFieldValue('color', e.target.value)} />
                                        <span className="square" style={{ backgroundColor: "#FFEC59" }}></span>
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 col-sm-6 form-group">
                                <label>Hạn đăng kiểm</label>
                                <DateTimePicker onChange={(value) => { handleDate('han_dang_kiem', value) }} value={dateSelect.han_dang_kiem ? new Date(dateSelect.han_dang_kiem) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                            </div>
                            <div className="col-12 col-sm-6 form-group">
                                <label>Trạng thái</label>
                                <select className="form-select height32" {...formik.getFieldProps("status")}>
                                    <option value="0">Dừng hoạt động</option>
                                    <option value="1">Hoạt động</option>
                                </select>
                            </div>
                            <div className="col-xs-12"></div>
                            <div className="col-12 col-sm-6 form-group">
                                <label>Người quản lý: (Người duyệt xe)</label>
                                <SearchBoxModal apiname={"users"} placeholder="Chọn người quản lý" colvalue={'id'} colname={'fullname'} defaultValue={formik.values['aprover_id']} name='aprover_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('aprover_id', e)
                                    }}></SearchBoxModal>
                            </div>
                            <div className="col-12 col-sm-6 form-group">
                                <label>Chọn tài xế</label>
                                <SearchBoxModal apiname={"users"} placeholder="Chọn tài xế" colvalue={'id'} colname={'fullname'} defaultValue={formik.values['drive_id']} name='drive_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('drive_id', e)
                                    }}></SearchBoxModal>
                            </div>

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
