import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import * as Yup from "yup";
import DateTimePicker from "react-datetime-picker";
import AlertErrorForm from "../component/AlertErrorForm";
import moment from "moment";
import Spinner from "../component/Spinner";
import { confirmAlert } from "react-confirm-alert";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import AkkhorLayout from "../layout/akkhor";
import SearchBoxModal from "../component/SearchBoxModal";

export default function FormUngVien() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [loading, setLoading] = useState(false)
    const [detail, setDetail] = useState()
    const [dateSelect, setDateSelect] = useState({
        birthday: null, cccd_date: null, namsinhcon_1: null, namsinhcon_2: null, namsinhcon_3: null, datejoining: null
    });
    const [autoRefresh, setAutoRefresh] = useState(false);

    const defaultdetail = {
        fullname: '',
        email: '',
        mobile: '',
        sex: '',
        chieucao: '',
        cannang: '',
        birthday: '',
        cccd: '',
        vitriungtuyen: '',
        tenphongban: '',
        user_modified: user.data.id,
        bank_name:'',
    }
    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            fullname: Yup.string().trim().nullable().required('Nhập họ tên').max(150, "Độ dài không quá 150 ký tự"),
            email: Yup.string().trim().nullable().required('Nhập email').max(150, "Độ dài không quá 150 ký tự"),
            mobile: Yup.string().trim().nullable().required('Nhập số điện thoại').max(15, "Độ dài không quá 15 ký tự"),
            birthday: Yup.string().trim().nullable().required('Nhập ngày sinh'),
            cccd: Yup.string().trim().nullable().required('Nhập Số cccd/cmnd'),
            cccd_date: Yup.string().trim().nullable().required('Nhập ngày cấp cccd/cmnd'),
            cccd_address: Yup.string().trim().nullable().required('Nhập nơi cấp cccd/cmnd'),
            sex: Yup.string().trim().nullable().required('Nhập giới tính'),
            vitriungtuyen: Yup.string().trim().nullable().required('Nhập vị trí ứng tuyển'),
            tenphongban: Yup.string().trim().nullable().required('Nhập tên phòng ban muốn ứng tuyển'),
            chuyennganh: Yup.string().trim().nullable().required('Nhập chuyên ngành'),
            daihoc: Yup.string().trim().nullable().required('Nhập trường đào tạo'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            values.highschool = values.daihoc
            values.majors = values.chuyennganh
            if(values.namsinhcon_1 && values.namsinhcon_1 == 'Invalid date') {
                values.namsinhcon_1 = null
            }
            if(values.namsinhcon_2 && values.namsinhcon_2 == 'Invalid date') {
                values.namsinhcon_2 = null
            }
            if(values.namsinhcon_3 && values.namsinhcon_3 == 'Invalid date') {
                values.namsinhcon_3 = null
            }
            setLoading(true)
            if(id > 0){
                Axios.put(URL_LIST_ALL + 'users_new/'+id,values,user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    if (res.data.status === "success") {
                        alert('Duyệt thành công')
                    }else{
                        alert(res.data.message)
                    }
                }).catch(err => console.log(err));
            }else{
                Axios.post(URL_LIST_ALL + 'registry', values).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    if (res.data.status === "success") {
                        confirmAlert({
                            title: "Thông báo",
                            message: "Cảm ơn bạn đã bổ sung thông tin. Vui lòng đợi phòng nhân sự phê duyệt thông tin.",
                            buttons: [{
                                label: "Có",
                                onClick: () => {
                                    resetForm()
                                },
                            }, { label: "Không" },
                            ],
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    useEffect(
        function () {
            // console.log(checkLeave);
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "users_new/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            console.log(res.data.data)
                            if (mounted) {
                                const dataif = res.data.data
                                setDetail(dataif);
                                setDateSelect({
                                    birthday: dataif.birthday,
                                    cccd_date: dataif.cccd_date,
                                    namsinhcon_1: dataif.namsinhcon_1 && dataif.namsinhcon_1 != 'Invalid date' ? dataif.namsinhcon_1 : null ,
                                    namsinhcon_2: dataif.namsinhcon_2 && dataif.namsinhcon_2 != 'Invalid date' ? dataif.namsinhcon_2 : null ,
                                    namsinhcon_3: dataif.namsinhcon_3 && dataif.namsinhcon_3 != 'Invalid date' ? dataif.namsinhcon_3 : null ,
                                    datejoining: dataif.datejoining
                                })
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, autoRefresh]
    );

    const handleDate = (name, value) => {
        formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'))
        formik.setFieldTouched(name)
        let newDate = Object.assign({}, dateSelect)
        newDate[name] = value
        setDateSelect(newDate)
    };

    return (
        <AkkhorLayout idrole="users_new">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/users_new">Quản lý ứng viên</Link>
                    </li>
                    <li>{id ? 'Sửa thông tin' : 'Thêm mới ứng viên'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <form className="w-100 bg-white new-added-form rounded shaddow login100-form validate-form p-2" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <h3 className="w-100 text-center mb-2">Sửa thông tin ứng viên</h3>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Họ và tên <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off" />
                                <AlertErrorForm formik={formik} name="fullname"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Điện thoại <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mobile")} />
                                <AlertErrorForm formik={formik} name="mobile"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Email <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("email")} />
                                <AlertErrorForm formik={formik} name="email"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Link facebook</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("linkfacebook")} />
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Ngày sinh <span className="text-danger">*</span></label>
                                <DateTimePicker onChange={(value) => { handleDate('birthday', value) }} value={dateSelect.birthday ? new Date(dateSelect.birthday) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                <AlertErrorForm formik={formik} name="birthday"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Địa chỉ liên lạc</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("address")} />
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Giới tính <span className="text-danger">*</span></label>
                                <select className="form-select height32" {...formik.getFieldProps("sex")}>
                                    <option value="" disabled>Hãy chọn giới tính</option>
                                    <option value="Nam">Nam</option>
                                    <option value="Nữ">Nữ</option>
                                    <option value="Khác">Khác</option>
                                </select>
                                <AlertErrorForm formik={formik} name="sex"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Số CCCD/CMND: <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("cccd")} />
                                <AlertErrorForm formik={formik} name="cccd"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Ngày cấp CCCD/CMND: <span className="text-danger">*</span></label>
                                <DateTimePicker onChange={(value) => { handleDate('cccd_date', value) }} value={dateSelect.cccd_date ? new Date(dateSelect.cccd_date) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                <AlertErrorForm formik={formik} name="cccd_date"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Nơi cấp CCCD/CMND: <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("cccd_address")} />
                                <AlertErrorForm formik={formik} name="cccd_address"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Địa chỉ thường trú: <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("address_tc")} />
                                <AlertErrorForm formik={formik} name="address_tc"></AlertErrorForm>
                            </div>
                            <div className="col-12"></div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Chiều cao (cm)</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("chieucao")} />
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Cân nặng (kg)</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("cannang")} />
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Sở thích</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("sothich")} />
                            </div>
                            <div className="col-12 col-sm-3 form-group">
                                <label>Mã số thuế</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mst_id")} />
                            </div>
                            <div className="col-12 col-sm-3 form-group">
                                <label>Số sổ bhxh</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bhxh_id")} />
                            </div>
                            <div className="col-12 col-sm-3 form-group">
                                <label>Người quen làm việc tại BHS ?</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("nguoiquen")} />
                            </div>
                            <div className="col-12 col-sm-3 form-group">
                                <label>Tên người quen</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("tennguoiquen")} />
                            </div>
                            <div className="col-12"></div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Chọn ngân hàng</label>
                                <select className="form-select height32" {...formik.getFieldProps("bank_name")}>
                                    <option value="Techcombank">Techcombank</option>
                                    <option value="BIDV">BIDV</option>
                                    <option value="MBBank">MBBank</option>
                                </select>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Số TK ngân hàng:	</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bank_id")} />
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12 form-group">
                                <label>Chi nhánh:	</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bank_address")} />
                            </div>
                            

                            <div className="col-12"></div>
                            <div className="col-12 col-sm-2 form-group">
                                <label>Vị trí ứng tuyển <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("vitriungtuyen")} />
                                <AlertErrorForm formik={formik} name="vitriungtuyen"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-2 form-group">
                                <label>Thuộc phòng ban <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("tenphongban")} />
                                <AlertErrorForm formik={formik} name="tenphongban"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-2 form-group">
                                <label>Công ty <span className="text-danger">*</span></label>
                                <SearchBoxModal apiname={"company"} placeholder="Chọn công ty" colvalue={'id'} colname={'title'} defaultValue={formik.values['company_id']} name='company_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('company_id', e)
                                    }}></SearchBoxModal>
                                <AlertErrorForm formik={formik} name="company_id"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-3 form-group">
                                <label>Phòng ban <span className="text-danger">*</span></label>
                                <SearchBoxModal apiname={"parts"} placeholder="Chọn phòng ban" colvalue={'id'} colname={'title'} defaultValue={formik.values['part_id']} name='part_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('part_id', e)
                                    }}></SearchBoxModal>
                                <AlertErrorForm formik={formik} name="part_id"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-3  form-group">
                                <label>Chức vụ <span className="text-danger">*</span></label>
                                <SearchBoxModal apiname={"chucvu"} placeholder="Chọn chức vụ" colvalue={'id'} colname={'title'} defaultValue={formik.values['chucvu_id']} name='chucvu_id'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('chucvu_id', e)
                                    }}></SearchBoxModal>
                                <AlertErrorForm formik={formik} name="chucvu_id"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Ngày bắt đầu làm việc: </label>
                                <DateTimePicker onChange={(value) => { handleDate('datejoining', value) }} value={dateSelect.datejoining ? new Date(dateSelect.datejoining) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                            <h3 className="col-12 mb-2">Học vấn</h3>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Tên trường cấp 1: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("truongcap_1")} />
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Năm tốt nghiệp: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namtotnghiep_1")} />
                            </div>
                            <div className="col-12"></div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Tên trường cấp 2: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("truongcap_2")} />
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Năm tốt nghiệp: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namtotnghiep_2")} />
                            </div>
                            <div className="col-12"></div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Tên trường cấp 3: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("truongcap_3")} />
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Năm tốt nghiệp: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namtotnghiep_3")} />
                            </div>
                            <div className="col-12"></div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Tên trường đại học/cao đẳng: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("daihoc")} />
                                <AlertErrorForm formik={formik} name="daihoc"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Chuyên ngành đào tạo: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("chuyennganh")} />
                                <AlertErrorForm formik={formik} name="chuyennganh"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Năm tốt nghiệp: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namtotnghiep_4")} />
                            </div>
                            <div className="col-12"></div>
                            <div className="col-12">
                                <hr />
                            </div>
                            <h3 className="col-12 mb-2">Gia đình</h3>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Tình trạng hôn nhân</label>
                                <select className="form-select height32" {...formik.getFieldProps("marital_status")}>
                                    <option value="Chưa kết hôn" >Chưa kết hôn</option>
                                    <option value="Đã kết hôn" >Đã kết hôn</option>
                                    <option value="Đã ly hôn" >Đã ly hôn</option>
                                </select>
                            </div>
                            <div className="col-12"></div>

                            {((formik.values.marital_status && formik.values.marital_status === "Đã kết hôn") || (formik.values.marital_status && formik.values.marital_status === "Đã ly hôn")) &&
                                <>
                                    {formik.values.marital_status === "Đã kết hôn" &&
                                        <>
                                            <div className="col-12 col-sm-4 form-group">
                                                <label>Họ Tên vợ/chồng: </label>
                                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("ten_vo_chong")} />
                                            </div>
                                            <div className="col-12 col-sm-4 form-group">
                                                <label>Năm sinh: </label>
                                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namsinh_vo_chong")} />
                                            </div>
                                            <div className="col-12 col-sm-4 form-group">
                                                <label>Số điện thoại vợ/chồng: </label>
                                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("phone_love")} />
                                            </div>
                                        </>
                                    }
                                    <div className="col-12 col-sm-3 form-group">
                                        <label>Tên con 1: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("tencon_1")} />
                                    </div>
                                    <div className="col-12 col-sm-3 form-group">
                                        <label>Sinh nhật con 1: </label>
                                        <DateTimePicker onChange={(value) => { handleDate('namsinhcon_1', value) }} value={dateSelect.namsinhcon_1 ? new Date(dateSelect.namsinhcon_1) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                    </div>

                                    <div className="col-12 col-sm-3 form-group">
                                        <label>Tên con 2: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("tencon_2")} />
                                    </div>
                                    <div className="col-12 col-sm-3 form-group">
                                        <label>Sinh nhật con 2: </label>
                                        <DateTimePicker onChange={(value) => { handleDate('namsinhcon_2', value) }} value={dateSelect.namsinhcon_2 ? new Date(dateSelect.namsinhcon_2) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                    </div>

                                    <div className="col-12 col-sm-3 form-group">
                                        <label>Tên con 3: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("tencon_3")} />
                                    </div>
                                    <div className="col-12 col-sm-3 form-group">
                                        <label>Sinh nhật con 3: </label>
                                        <DateTimePicker onChange={(value) => { handleDate('namsinhcon_3', value) }} value={dateSelect.namsinhcon_3 ? new Date(dateSelect.namsinhcon_3) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                    </div>

                                    <div className="col-12 col-sm-3 form-group">
                                        <label>Tên con 4: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("tencon_3")} />
                                    </div>
                                    <div className="col-12 col-sm-3 form-group">
                                        <label>Sinh nhật con 4: </label>
                                        <DateTimePicker onChange={(value) => { handleDate('namsinhcon_3', value) }} value={dateSelect.namsinhcon_3 ? new Date(dateSelect.namsinhcon_3) : null} format="dd-MM-y" className="form-control height32 air-datepicker"></DateTimePicker>
                                    </div>
                                </>
                            }
                            <div className="col-12"></div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Họ tên bố đẻ: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("ten_bo")} />
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Năm sinh: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namsinh_bo")} />
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Nghề nghiệp: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("nghenghiepbo")} />
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Họ tên mẹ đẻ: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("ten_me")} />
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Năm sinh: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namsinh_me")} />
                            </div>
                            <div className="col-12 col-sm-4 form-group">
                                <label>Nghề nghiệp: </label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("nghenghiepme")} />
                            </div>
                            {(formik.values.marital_status && formik.values.marital_status === "Đã kết hôn") ?
                                <>
                                    <div className="col-12 col-sm-4 form-group">
                                        <label>Họ tên bố vợ/chồng: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("ten_bo_2")} />
                                    </div>
                                    <div className="col-12 col-sm-4 form-group">
                                        <label>Năm sinh: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namsinh_bo_2")} />
                                    </div>
                                    <div className="col-12 col-sm-4 form-group">
                                        <label>Nghề nghiệp: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("nghenghiepbo_2")} />
                                    </div>
                                    <div className="col-12 col-sm-4 form-group">
                                        <label>Họ tên mẹ vợ/chồng: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("ten_me_2")} />
                                    </div>
                                    <div className="col-12 col-sm-4 form-group">
                                        <label>Năm sinh: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("namsinh_me_2")} />
                                    </div>
                                    <div className="col-12 col-sm-4 form-group">
                                        <label>Nghề nghiệp: </label>
                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("nghenghiepme_2")} />
                                    </div>

                                </>
                                : ''
                            }
                            <div className="col-12 d-flex justify-content-center form-group mt-5">
                                <button type="submit" disabled={formik.isSubmitting} className="btn pl-5 pr-5 btn-success text-white">{loading ? <Spinner /> : <>Duyệt ứng viên</>}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>)

}
